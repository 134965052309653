import { createTheme } from '@mui/material/styles';
import { colors } from './colors';
import popoverBlurBlue from '../../assets/popover-blur-blue.png';
import popoverBlurRed from '../../assets/popover-blur-red.png';

export const theme = createTheme({
  typography: {
    fontFamily: ['"Public Sans"', 'sans-serif'].join(','),
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            fontSize: '0.875rem',
            color: colors.inputLabelColor,

            '&.MuiInputLabel-shrink': {
              fontSize: '1rem',
              fontWeight: 600,
            },
            '&.Mui-focused': {
              color: colors.inputFocusColor,
              fontWeight: 600,
              fontSize: '1rem',
            },
            '&.Mui-error': {
              color: colors.inputErrorColor,
            },
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: colors.inputFocusColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.inputFocusColor,
            },
            '&.Mui-error fieldset': {
              borderColor: colors.inputErrorColor,
            },
            '& fieldset': {
              borderRadius: `0.5rem`,
              borderColor: colors.inputBorderColor,
            },
            '& input': {
              fontSize: '0.875rem',

              '&::placeholder': {
                color: colors.inputLabelColor,
                opacity: '1',
              },
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          fontWeight: '400',
          lineHeight: '1.5',
          letterSpacing: 'normal',
          margin: '0.5rem 0.875rem 0',

          '&.Mui-error': {
            borderColor: colors.inputErrorColor,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          fontSize: '0.938rem',
          textTransform: 'none',
          fontWeight: '700',
        },
        contained: {
          backgroundColor: colors.buttonContainedPrimaryColor,

          '&:hover': {
            backgroundColor: colors.buttonContainedPrimaryColorHover,
          },
        },
        outlined: {
          backgroundColor: colors.buttonOutlinedPrimaryColor,
          borderColor: colors.buttonOutlinedPrimaryColorBorder,
          color: 'inherit',

          '&:hover': {
            backgroundColor: colors.buttonOutlinedPrimaryColorHover,
            borderColor: colors.buttonOutlinedPrimaryColorBorder,
          },
        },
        text: {
          '&.MuiButton-colorError': {
            backgroundColor: colors.buttonErrorBackgroundColor,
            color: colors.buttonErrorColor,

            '&:hover': {
              backgroundColor: colors.buttonErrorBackgroundColorHover,
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: '100%',

          '::before': {
            borderTopStyle: 'dashed',
            borderTopWidth: 'thin',
            borderTopColor: colors.dividerBorderColor,
          },
          '::after': {
            borderTopStyle: 'dashed',
            borderTopWidth: 'thin',
            borderTopColor: colors.dividerBorderColor,
          },

          '& .MuiDivider-wrapper': {
            fontSize: '0.75rem',
            fontWeight: '700',
            color: colors.dividerColor,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: colors.progressBackgroundColor,

          '& .MuiLinearProgress-bar1Indeterminate': {
            backgroundColor: colors.progressColor,
          },

          '& .MuiLinearProgress-bar2Indeterminate': {
            backgroundColor: colors.progressColor,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: colors.progressColor,
          },

          '& .MuiTab-textColorPrimary': {
            color: colors.tabDefaultColor,
            fontWeight: '600',
          },

          '& .Mui-selected': {
            color: 'inherit !important',
            fontWeight: '600',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: colors.tableHeadBackgroundColor,
          fontWeight: '600',
          fontSize: '14px',
          color: colors.tableHeadColor,
          borderBottomColor: colors.tableLineBorderColor,
        },
        body: {
          color: colors.textColor,
          lineHeight: '1.57143',
          fontSize: '0.875rem',
          fontWeight: '400',
          borderBottomColor: colors.tableLineBorderColor,
        },
        paddingCheckbox: {
          padding: '0 0 0 8px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '8px',

          '&.Mui-checked': {
            color: colors.checkboxColor,
          },

          '&.MuiCheckbox-indeterminate': {
            color: colors.checkboxColor,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h4: {
          letterSpacing: 'normal',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          lineHeight: 1.57143,

          '&.MuiAlert-colorError': {
            backgroundColor: colors.alertErrorBackgroundColor,
            color: colors.alertErrorColor,
          },

          '&.MuiAlert-colorWarning': {
            backgroundColor: colors.alertWarningBackgroundColor,
            color: colors.alertWarningColor,
          },

          '&.MuiAlert-colorSuccess': {
            backgroundColor: colors.alertSuccessBackgroundColor,
            color: colors.alertSuccessColor,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          color: colors.textColor,
          backdropFilter: 'blur(20px)',
          backgroundImage: `url(${popoverBlurBlue}), url(${popoverBlurRed})`,
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundPosition: 'right top, left bottom',
          backgroundSize: '50% 50%',
          boxShadow: `rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px`,
          width: '200px',
          overflow: 'inherit',
          padding: '0',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: '400',
          padding: '6px 8px',
          borderRadius: '6px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingLeft: '8px',
          paddingRight: '8px',
          whiteSpace: 'nowrap',
          fontWeight: '500',
          borderRadius: '8px',

          '&:hover': {
            backgroundColor: 'rgba(145, 158, 171, 0.32)',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: '10000',
        },
        paper: {
          backgroundImage: `url('../../assets/popover-blur-blue.png'), url('../../assets/popover-blur-red.png')`,
          backgroundRepeat: `no-repeat, no-repeat`,
          backgroundSize: `50%, 50%`,
          backgroundPosition: `right top, left bottom`,
          backdropFilter: `blur(20px)`,
        },
        listbox: {
          padding: '8px',

          '.MuiAutocomplete-option': {
            borderRadius: '6px',
            padding: '6px 8px',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          marginLeft: '6px',

          '&.Mui-checked': {
            marginLeft: '0',
          },
        },

        thumb: {
          color: colors.switcherThumbColor,
          width: '14px',
          height: '14px',
        },

        track: {
          height: '20px',
          backgroundColor: colors.switcherTrackColor,
          borderRadius: '10px',
          opacity: '1',

          '.Mui-checked.Mui-checked + &': {
            opacity: 1,
            backgroundColor: colors.switcherColor,
          },
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        slotProps: {
          select: {
            MenuProps: {
              slotProps: {
                paper: {
                  sx: {
                    width: 'auto',
                  },
                },
              },
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.tooltipColor,
        },
        arrow: {
          color: colors.tooltipColor,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          backgroundColor: colors.tableLineHoverColor,
        },
      },
    },
  },
});
