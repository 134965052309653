import React, { useEffect, useState } from 'react';
import styles from './NavigationDrawer.module.scss';
import { Drawer, IconButton as MuiIconButton } from '@mui/material';
import menuDrawerIcon from '../../../../../assets/icons/menu-drawer.svg';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import { useLocation } from 'react-router-dom';

export const NavigationDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <>
      <MuiIconButton
        onClick={() => setIsOpen(true)}
        size="small"
        className={styles.navigationDrawer}
      >
        <img src={menuDrawerIcon} />
      </MuiIconButton>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className={styles.drawerContainer}
      >
        <AdminSidebar />
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
